var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: `DATAVIEW_FOLDER_FORM_${_vm.id}` },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.canEdit() || !_vm.exists
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.parent_folder"),
                "label-for": "parent",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { id: "parent", type: "text", disabled: true },
                    model: {
                      value: _vm.parent.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.parent, "name", $$v)
                      },
                      expression: "parent.name",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm" },
                          on: { click: _vm.modalRemove },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.remove")))]
                      ),
                      _vm.canList()
                        ? _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "info" },
                              on: { click: _vm.modalSelect },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.select")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.folder_name"),
                "label-for": "name",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }",
                      },
                    ],
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("dataview.folder_name"),
                      "data-vv-name": "folder.name",
                      maxlength: _vm.maxNameLength,
                      "data-vv-delay": "500",
                      disabled: _vm.isReadOnly,
                      autofocus: true,
                      state: _vm.fieldValidateUtil.stateValidate(
                        _vm.isReadOnly,
                        _vm.veeFields,
                        _vm.errors,
                        "folder.name"
                      ),
                      trim: "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        return _vm.keydownHandler.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.folder.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.folder, "name", $$v)
                      },
                      expression: "folder.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("folder.name")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modalShowSelector
        ? _c("DataviewFolderSelectorModal", {
            attrs: {
              exclude: _vm.folder.uuId,
              preselected: _vm.parent.uuId,
              visibility: _vm.visibility,
              title: _vm.$t("dataview.title_parent_selector"),
              show: _vm.modalShowSelector,
              forceSingleSelection: true,
              mode: "SELECT",
            },
            on: {
              "update:show": function ($event) {
                _vm.modalShowSelector = $event
              },
              ok: _vm.modalSuccessSelector,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }