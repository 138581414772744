var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "STAGE_BADGE_GROUP_MODAL" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            title:
              _vm.customTitle != null ? _vm.customTitle : "Edit Assigned Stage",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hide: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(
                  _vm._s(
                    _vm.customLabel != null
                      ? _vm.customLabel
                      : _vm.$t("badge.stage")
                  )
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-action",
                  on: { click: _vm.stageSelectorToggle },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
                1
              ),
              _c("BadgeGroup", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        _c("Badge", {
                          key: index,
                          attrs: {
                            text: "" + item.name,
                            variant: "primary",
                            pillable: !!item.pillable,
                          },
                          on: {
                            badgeRemove: function ($event) {
                              return _vm.stageBadgeRemove(index)
                            },
                            badgeClick: function ($event) {
                              return _vm.stageBadgeClick(item.uuId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.stages,
                  callback: function ($$v) {
                    _vm.stages = $$v
                  },
                  expression: "stages",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.stageSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.stageSelectorShow,
              entityService: _vm.stageUtil,
              preselected: _vm.stageEditId,
              entity: "STAGE",
              nonAdmin: "",
              singleSelection: !_vm.multiple,
              tagFilter: _vm.tagFilter,
            },
            on: {
              "update:show": function ($event) {
                _vm.stageSelectorShow = $event
              },
              cancel: _vm.stageSelectorCancel,
              ok: _vm.stageSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }